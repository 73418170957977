<template>
  <main id="content" role="main">
    <section class="report_group">
      <section class="report_box">
        <div class="letter-read">
          <div class="letter-comm-msg">
            <div class="logo-area">
              <div class="type-img"></div>
              <div class="type-text">
                <span>DREAMPLUS</span>
                <strong>NEWSLETTER</strong>
              </div>
            </div>
            <div class="msg-area">
              <strong>DREAMPLUS NEWSLETTER</strong>를 통해<br>
              드림플러스의 다양한 활동 및 생태계 소식을 전해 드립니다.
            </div>
          </div>
          <ValidationObserver ref="form">
            <form name="">
              <div class="form-box">
                <div class="inp-box">
                  <label>이메일 주소를 알려주세요<span class="required"></span></label>
                  <ValidationProvider ref="emailProvider" name="email" rules="requiredEmail" v-slot="{ errors }">
                    <input type="text" name="" v-model="email" maxlength="50"/>
                    <span v-if="errors[0]" class="input-error-desc">{{errors[0]}}</span>
                  </ValidationProvider>
                </div>
              
                <div class="inp-box">
                  <label>이름을 알려주세요<span class="required"></span></label>
                  <ValidationProvider ref="nameProvider" name="이름" rules="requiredName" v-slot="{ errors }">
                    <input type="text" name="" v-model="name" maxlength="50"/>
                    <span v-if="errors[0]" class="input-error-desc">{{errors[0]}}</span>
                  </ValidationProvider>
                </div>
                <div class="inp-box">
                  <label>어떤 일을 하시나요? 여러분이 궁금해요</label>
                  <input type="text" name="" v-model="job" maxlength="50"/>
                </div>
                <div class="chk-box">
                  <ValidationProvider ref="privacyProvider" name="privacy" rules="requiredConsent" v-slot="{ errors }">
                    <input type="checkbox" name="privacy" id="chk" v-model="isCheck"/>
                    <label for="chk">[필수] <span @click.prevent="test">개인정보 수집, 이용에 동의합니다.</span></label>
                    <span v-if="errors[0]" class="input-error-desc" v-html="errors[0]"></span>
                  </ValidationProvider>
                </div>
                <button type="button" class="btn" :disabled="!isComplete" @click.prevent="submit()">구독하기</button>
              </div>
            </form>
          </ValidationObserver>
          <div class="text-center">
            <router-link :to="{name:'LetterList', params:{}}"  class="link-old">지난호 보기</router-link>
          </div>
        </div>
      </section>
    </section>
    <div class="alert-box">
      <div class="box">
        <div class="close-icon" @click="test2"></div>
        <div class="title">개인정보 수집 및 이용</div>
        <div class="content">
          뉴스레터 발송을 위한 최소한의 개인정보를 수집하고 이용합니다.
          수집된 정보는 발송 외 다른 목적으로 이용되지 않으며,
          서비스가 종료되거나 구독을 해지할 경우 즉시 파기 됩니다.
        </div>
        <a class="close-button" @click="test2">닫기</a>
      </div>
    </div>
  </main>
</template>

<script>

import { mixin as mixinHelperUtils } from '@/common/helperUtils';
import { APIs } from '@/common/portalApi';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import {
  required
} from 'vee-validate/dist/rules';

const emailRegex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
extend('requiredEmail', {
  ...required,
  params: ['name'],
  validate(val) {
    if (!val) {
      return `이메일 주소를 입력하세요.`
    } else if (!emailRegex.test(val)) {
      return `잘못된 이메일 주소입니다.`
    }
    return true
  },
});
extend('requiredName', {
  ...required,
  params: ['name'],
  validate(val) {
    if (!val) {
      return `이름을 입력하세요.`
    }

    return true
  },
});
extend('requiredConsent', {
  ...required,
  params: ['name'],
  validate(val) {
    console.log('requiredConsent', val)
    if (!val) {
      return `개인정보 수집 및 이용에 동의하지 않으면<br/>서비스 이용이 불가합니다.<br/>이용에 동의해주세요.`
    }

    return true
  },
});

export default {
  name: 'LetterRead',
  mixins: [
    mixinHelperUtils,
  ],

  components: {
    ValidationObserver,
    ValidationProvider
  },
  
  data () {
    return {
      email: null,
      name: null,
      job: null,
      isCheck: false,
      isSubscription: true,
      channel: null
    }
  },

  created() {
  },

  watch: {
    'isCheck': function() {

      this.validateForm(this.$refs.form)
      
    }
  },
  
  mounted() {
    this.userInfo = this.$store.getters.getUserInfo();
    if (!this.userInfo) {
      this.channel = 14 // 비회원
    } else if (this.userInfo.id) {
      this.email = this.userInfo.email
      this.channel = 13 // 일반회원
    }
    if (this.$route.params.email) {
      this.email = this.$route.params.email
    }
  },

  computed: {
    isComplete () {
      return this.email && this.name && this.isCheck;
    }
  },
  
  methods: {
    test() {
      document.querySelector('.alert-box').classList.add('show');
    },
    
    test2() {
      document.querySelector('.alert-box').classList.remove('show');
    },

    checkForm: function (e) {
      const { emailProvider } = this.$refs;
      const { nameProvider } = this.$refs;

      emailProvider.validate().then((result) => {
        if (result.valid) {
        }
        else { 
          this.showAlert(result.errors[0]);
          //this.showAlert('invalid email');
          return false;
        }
      });

      nameProvider.validate().then((result) => {
        if (result.valid) {
        }
        else { 
          this.showAlert(result.errors[0]);
          return false;
        }
      });
    },

    submit() {
      this.validateForm(this.$refs.form).then(() => {
        this.portalApiClient({
          url: APIs.NEWS_LETTER_SUBSCRIPTION,
          data: this.$data,
          }).then(({ data }) => {
            if (data.result) {
              this.$router.replace({ name: 'LetterMsg', params: { type: 1 } });
            } else {
              this.showAlert(data.message);
            }
          });
      });

      // if (this.checkForm())
      // {
      //   this.portalApiClient({
      //     url: APIs.NEWS_LETTER_SUBSCRIPTION,
      //     data: this.$data,
      //     }).then(({ data }) => {
      //       if (data.result) {
      //         this.$router.replace({ name: 'LetterMsg', params: { type: 1 } });
      //       } else {
      //         this.showAlert(data.message);
      //       }
      //     });
      // }
    },
  },
};
</script>
